<cdk-virtual-scroll-viewport
  #viewPort
  [itemSize]="itemSize"
  matSort
  [minBufferPx]="itemSize * 10"
  [maxBufferPx]="itemSize * 20"
>
  <table mat-table [dataSource]="dataSource$" [class]="tableClass">
    @for (column of config.columns; track column.id; let i = $index) {
      <ng-container matColumnDef="{{ column.id }}">
        <!-- Cell definitions -->
        <!-- Header -->
        <th
          mat-header-cell
          [mat-sort-header]="column.id"
          [disabled]="config.sortable === false || column.sortable === false"
          *matHeaderCellDef
          [class]="(column.type ? column.type : '') + ' mat-header-cell ' + (column.align ? column.align : '')"
          [style.top]="offset()"
        >
          @if (column.toggleHeader) {
            <mat-checkbox
              #chkHead
              (change)="toggleAll(column, chkHead.checked)"
              (click)="stopPropagation($event)"
              [checked]="allChecked(column, chkHead)"
            >
              <span>{{ getName(column) | translate }}</span>
            </mat-checkbox>
          } @else {
            <span>{{ getName(column) | translate }}</span>
          }
          <span *ngIf="column.addHead">{{ getAdditionalHead(column) }}</span>
          <small *ngIf="column.unit">[{{ getUnit(column) }}]</small>
        </th>

        <!-- Body -->
        <td
          mat-cell
          *matCellDef="let element"
          [class]="
            (column.type ? column.type : '') +
            ' mat-cell ' +
            (column.align ? column.align : '') +
            ' ' +
            column.className
          "
          [ngClass]="classList(column, element)"
          [title]="
            column.showTooltip && column.tooltipText
              ? column.tooltipText(element)
              : column.showTooltip
                ? element[getKey(column)]?.split(', ').join('\n') || content.textContent
                : content.textContent
          "
        >
          <div class="table-cell-wrapper" #content>
            @if (column.filterable) {
              <button mat-icon-button type="button" (click)="onFilter($event, element, column)" class="filter">
                <mat-icon>filter_alt</mat-icon>
              </button>
            }

            @if (column.showIf == null || column.showIf(element)) {
              <ng-container *ngIf="column.routeTo; else content">
                <!-- Render anchor content -->
                <a [routerLink]="column.routeTo(element)" [queryParams]="queryParams(column, element)">
                  <ng-container [ngTemplateOutlet]="content"></ng-container>
                </a>
              </ng-container>
              <ng-template #content>
                @if (column.formatter != null) {
                  <!-- Render custom formatted content -->
                  @if (column.formatAsHtml) {
                    <span [innerHTML]="formatAsHtml(column, element)"></span>
                  } @else {
                    <span>{{ format(column, element) | translate }}</span>
                  }
                } @else {
                  <!-- Default renderers -->
                  @switch (column.type) {
                    @case ('numeric') {
                      <span>{{ element[getKey(column)] }}</span>
                    }
                    @case ('number') {
                      <span>{{ element[getKey(column)] | numeric: 'decimal' : column.decimals }}</span>
                    }
                    @case ('percent') {
                      <span>{{ element[getKey(column)] | numeric: 'percent' : column.decimals }}</span>
                    }
                    @case ('date') {
                      <span>{{ element[getKey(column)] | logicDate }}</span>
                    }
                    @case ('datetime') {
                      <span>
                        {{ element[getKey(column)] | logicDate }}
                        {{ element[getKey(column)] | date: 'HH:mm' }}
                      </span>
                    }
                    @case ('button') {
                      <button
                        type="button"
                        (click)="column?.action(element, column)"
                        [className]="column.className || ''"
                      >
                        @if (column.icon) {
                          @if (getIcon(column, element) === '__spinner__') {
                            <lib-loader [inline]="true" [diameter]="20" [isLoading]="true"></lib-loader>
                          } @else {
                            <span class="material-icons">{{ getIcon(column, element) }}</span>
                          }
                        }
                        @if (column.label) {
                          <span>{{ column.label | translate }}</span>
                        }
                      </button>
                    }
                    @case ('boolean') {
                      @if (!column.disabled) {
                        <mat-checkbox
                          [checked]="element[getKey(column)]"
                          (click)="changeBooleanValue(element, column, $event)"
                          [color]="column.color"
                          [disabled]="column.changeable === false"
                        ></mat-checkbox>
                      } @else {
                        @if (element[getKey(column)]) {
                          <mat-icon>check</mat-icon>
                        } @else {
                          <span>&nbsp;</span>
                        }
                      }
                    }
                    @default {
                      @if (column.translatable !== false) {
                        <span>{{ element[getKey(column)] | translate }}</span>
                      } @else {
                        <span>{{ element[getKey(column)] }}</span>
                      }
                    }
                  }
                }
              </ng-template>
            }
          </div>
        </td>

        <!-- Footer -->
        <td
          mat-footer-cell
          *matFooterCellDef
          [class]="
            (i == 0 ? 'sum ' : '') +
            (column.type ? column.type : '') +
            ' mat-footer-cell ' +
            (column.align ? column.align : '')
          "
          [style.bottom]="'calc(' + offset() + ' * -1)'"
        >
          @if (i > 0) {
            <div *ngIf="column.summarize">{{ sum(getKey(column)) | numeric: 'decimal' : column.decimals }}</div>
            <div *ngIf="column.average">{{ avg(getKey(column)) | numeric: 'decimal' : column.decimals }}</div>
          } @else {
            <div *ngIf="shouldSummarize">{{ 'Sum' | translate }}</div>
            <div *ngIf="shouldAverage">{{ 'Avg' | translate }}</div>
          }
        </td>
      </ng-container>
    }

    <!-- Row definitions -->
    <!-- Header -->
    <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
    <!-- Body -->
    <tr
      mat-row
      *matRowDef="let row; columns: columnNames"
      (mouseenter)="onHighlight(row)"
      (mouseleave)="onReset(row)"
      [ngClass]="{ active: row._active, selected: row._selected }"
      [class]="row._class ? row._class : ''"
      (click)="onClick(row, $event)"
    ></tr>
    <!-- Footer -->
    @if (!config.hideFooter) {
      <tr
        mat-footer-row
        *matFooterRowDef="columnNames; sticky: true"
        [ngClass]="{ lined: shouldSummarize || shouldAverage }"
        (click)="onClickFooter($event)"
      ></tr>
    }
  </table>
</cdk-virtual-scroll-viewport>
