import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { isElementInView, scrollIntoView } from '../utils/scrollIntoView';
import { objToString } from '../utils/stringUtils';
import { ScrollableDataSource } from './scrollable-datasource';
import {
  ColumnFormatter,
  DEFAULT_TABLE_ROW_SIZE,
  TableColumn,
  TableConfig,
  TableFilterEvent,
  TableRow,
} from './table.config';
import { TableService } from './table.service';

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
export class TableComponent implements OnInit, DoCheck, OnDestroy {
  private readonly tableService = inject(TableService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly el = inject(ElementRef);

  static setRenderedContentOffset: any;
  @Input() config!: TableConfig;
  @Input() totalPages = 1;
  @Input() pageSize = 100;
  @Input() itemSize = 48;
  @Input() enableKeyboard: boolean | (($event: KeyboardEvent, data: TableRow[]) => void) = false;
  @Input() selectMultiple = false;
  @Input() keepScrollPosition = false;
  @Input() infinite = true;
  @Input() set full(flag: boolean) {
    this.tableClass = 'full-size';
  }
  @HostBinding('attr.tableclass')
  @Input()
  tableClass = '';
  @Input()
  set data(rows: TableRow[]) {
    // React to data changes
    this.dataSource$.data = rows || [];
    // if (rows == null || rows.length === 0) return;
    rows?.forEach((r) => {
      if (this.config?.preCalculatedRowClass != null) {
        // Add preCalculatedRowClass to row
        r._class = Object.entries(Object.assign({}, this.config.preCalculatedRowClass(r)))
          .filter(([k, v]) => v)
          .map(([k, v]) => k)
          .join(' ');
      }
    });
    setTimeout(() => {
      this.viewPort.checkViewportSize();
      if (!this.keepScrollPosition) {
        this.viewPort.scrollToIndex(0);
      }
    });
  }
  get data() {
    return this.dataSource$.data || [];
  }

  @Output() scrolled = new EventEmitter<{ scrollTop: number; viewPort: CdkVirtualScrollViewport }>();
  @Output() clicked = new EventEmitter();
  @Output() footerClicked = new EventEmitter();
  @Output() filterChanged = new EventEmitter<TableFilterEvent>();
  @Output() sortChange = new EventEmitter<Sort>();
  private __oldConfig?: string;

  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewPort!: CdkVirtualScrollViewport;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<TableRow>;
  dataSource$: ScrollableDataSource<any> = new ScrollableDataSource();
  offset = signal('');

  subscriptions: Subscription[] = [];
  setRenderedContentOffset: any;

  get columnNames() {
    return this.config?.columns?.map((c) => c.id).filter((c) => !(this.config.hidden || []).includes(c));
  }

  get shouldAverage() {
    return this.config?.columns?.some((c) => c.average);
  }
  get shouldSummarize() {
    return this.config?.columns?.some((c) => c.summarize);
  }
  hasShiftKey = false;

  constructor(@Optional() @Inject(DEFAULT_TABLE_ROW_SIZE) rowHeight: number) {
    if (rowHeight) this.itemSize = rowHeight;
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    if (this.infinite) {
      this.setRenderedContentOffset = CdkVirtualScrollViewport.prototype.setRenderedContentOffset;
      CdkVirtualScrollViewport.prototype.setRenderedContentOffset = function (offset, to = 'to-start') {
        me.offset.set(`${-offset}px`);
        me.setRenderedContentOffset.apply(this, [offset, to]);
      };
      this.subscriptions.push(
        this.viewPort
          .elementScrolled()
          .subscribe((n: Event) =>
            this.scrolled.emit({ scrollTop: (n.target as HTMLElement).scrollTop, viewPort: this.viewPort }),
          ),
      );
      this.dataSource$.attach(this.viewPort);
    }

    if (this.config.sortType !== 'remote') {
      // Default is 'local'
      this.dataSource$.sort = this.matSort;
    }
    this.subscriptions.push(
      this.matSort.sortChange.subscribe((sort: Sort) => {
        if (!sort.direction) {
          this.tableService.removeItem(`${this.config.id}.sort`);
        } else {
          this.tableService.setItem(`${this.config.id}.sort`, sort);
        }
        this.sortChange.emit(sort);
      }),
    );
  }

  ngDoCheck() {
    if (this.config != null && objToString(this.config) !== this.__oldConfig) {
      // React to config changes
      this.__oldConfig = objToString(this.config);
      this.config.onHighlight = this.config.onHighlight || this.highlight;
      this.config.onReset = this.config.onReset || this.reset;
      this.config.columns?.forEach((c) => {
        if (c.type === 'number') {
          c.summarize = c.summarize != null ? c.summarize : true;
          c.average = c.average != null ? c.average : true;
        }
      });

      // Apply sort
      if (this.tableService.hasItem(`${this.config.id}.sort`)) {
        const sort = this.tableService.getItem(`${this.config.id}.sort`);
        this.matSort.active = sort.active;
        this.matSort.direction = sort.direction;
        this.matSort.sortChange.emit(sort);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    CdkVirtualScrollViewport.prototype.setRenderedContentOffset = this.setRenderedContentOffset;
  }

  queryParams(column: TableColumn, row: TableRow) {
    if (column.queryParams) return column.queryParams(row);
    return {};
  }

  classList(column: TableColumn, row: TableRow) {
    if (column.classList) return column.classList(row);
    return '';
  }

  markAsDirty() {
    this.cdr.markForCheck();
  }

  redraw() {
    this.viewPort.checkViewportSize();
    // this.viewPort.elementScrolled();
  }

  format(column: TableColumn, element: TableRow): string {
    const value = this.tableService.formatValue(column, element[this.getKey(column)]);
    return (column.formatter as ColumnFormatter)(value, element) as string;
  }

  formatAsHtml(column: TableColumn, element: TableRow): SafeHtml {
    const value = this.tableService.formatValue(column, element[this.getKey(column)]);
    return (column.formatter as ColumnFormatter)(value, element) as SafeHtml;
  }

  getKey(column: TableColumn) {
    return column.key || column.id;
  }

  getName(column: TableColumn) {
    return typeof column.name === 'string' ? column.name : column.name();
  }

  getIcon(column: TableColumn, row: TableRow) {
    return typeof column.icon === 'function' ? column.icon(row) : column.icon;
  }
  getUnit(column: TableColumn) {
    if (!column.unit) return '';
    return typeof column.unit === 'string' ? column.unit : column.unit();
  }

  getAdditionalHead(column: TableColumn) {
    return typeof column.addHead === 'function' ? column.addHead() : column.addHead || '';
  }

  sum(prop: string) {
    return this.data?.reduce((acc, c) => (acc += +c[prop] || 0), 0);
  }

  avg(prop: string) {
    return this.sum(prop) / this.data?.filter((v) => !!v[prop]).length || 0;
  }

  private highlight(row: TableRow) {
    setTimeout(() => (row._active = true));
  }

  private reset(row: TableRow) {
    setTimeout(() => (row._active = false));
  }

  onClick(row: TableRow, $event?: MouseEvent) {
    // Cleanup old
    if (!this.selectMultiple || !this.hasShiftKey) {
      this.data.filter((v) => v._selected).forEach((v) => (v._selected = false));
    }
    // Select new
    row._selected = true;

    // Select anything in between if instructed to do so
    if (this.selectMultiple && this.hasShiftKey) {
      const lastRowIdx = this.data.findLastIndex((r: TableRow) => r._selected);
      let firstRowIdx = this.data.findIndex((r: TableRow) => r._selected);
      while (firstRowIdx < lastRowIdx) {
        this.data[firstRowIdx]._selected = true;
        firstRowIdx++;
      }
    }

    // Emit event
    this.clicked.emit(row);
    this.cdr.markForCheck();
  }

  onClickFooter($event?: MouseEvent) {
    this.footerClicked.emit();
    this.cdr.markForCheck();
  }

  onFilter($event: MouseEvent, row: TableRow, column: TableColumn) {
    $event.stopPropagation();
    this.filterChanged.emit({ row, column });
  }

  onHighlight(row: TableRow) {
    this.data.filter((v) => v._active).forEach((v) => (v._active = false));
    row._active = true;
    if (this.config.onHighlight) this.config.onHighlight(row);
    this.cdr.markForCheck();
  }

  onReset(row: TableRow) {
    row._active = false;
    if (this.config.onReset) this.config.onReset(row);
    this.cdr.markForCheck();
  }

  /**
   * Required to prevent rowclick event from undoing the selection
   * @param $event
   */
  changeBooleanValue(element: TableRow, column: TableColumn, $event: MouseEvent) {
    if (column.changeable !== false) {
      const key = this.getKey(column);
      element[key] = !element[key];
      if (column.onChange) column.onChange(element);
      this.cdr.markForCheck();
    }
  }

  allChecked(column: TableColumn, chk?: MatCheckbox) {
    const val = this.data.length > 1 && this.data.every((d) => d[this.getKey(column)] === true);
    if (val !== chk?.checked) {
      setTimeout(() => this.cdr.markForCheck());
    }
    return val;
  }

  toggleAll(column: TableColumn, flag: boolean) {
    let dirty = false;
    this.data.forEach((d) => {
      const val = d[this.getKey(column)];
      if (val != flag) {
        d[this.getKey(column)] = flag;
        dirty = true;
        if (column.onChange) column.onChange(d);
      }
    });
    if (dirty) this.cdr.markForCheck();
  }

  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }

  @HostListener('window:keydown', ['$event'])
  onKey(key: KeyboardEvent) {
    // Return if this event is triggered outside of the table. The table needs to be visible and in focus, or hovered.
    if (!this.el.nativeElement.contains(document.activeElement)) return;

    this.hasShiftKey = key.shiftKey;
    if (typeof this.enableKeyboard === 'function') {
      // If enableKeyboard is a function, this function should handle the event
      this.enableKeyboard(key, this.data);
    } else if (this.enableKeyboard === true) {
      // If enableKeyboard is true, handle the event internally
      if (['ArrowDown', 'ArrowUp', ' ', 'Enter'].includes(key.key)) {
        key.preventDefault();
        key.stopPropagation();
        const lastRowIdx = this.data.findLastIndex((r: TableRow) => r._active);
        const firstRowIdx = this.data.findIndex((r: TableRow) => r._active);
        const range = lastRowIdx - firstRowIdx;
        const selectMultiple = this.selectMultiple === true && key.shiftKey === true;

        // Cleanup
        if (!selectMultiple) this.data.forEach((r) => (r._active = undefined));

        // Set new active row
        let active;
        let activeRowIdx = selectMultiple ? lastRowIdx : firstRowIdx;
        switch (key.key) {
          case 'ArrowDown':
            // Find current active row
            activeRowIdx++;
            activeRowIdx = activeRowIdx > 0 ? activeRowIdx : 0;
            active = this.data[activeRowIdx];
            active._active = true;
            break;
          case 'ArrowUp':
            // Find current active row
            if (range > 0) {
              this.data[activeRowIdx]._active = undefined;
            }
            activeRowIdx--;
            activeRowIdx = activeRowIdx < this.data.length - 1 ? activeRowIdx : this.data.length - 1;
            active = this.data[activeRowIdx];
            active._active = true;
            break;
          case ' ':
          case 'Enter':
            active = this.data[activeRowIdx];
            this.onClick(active);
            break;
        }
        const activeEl = document.querySelector('tr.active');
        if (activeEl != null && !isElementInView(activeEl)) {
          scrollIntoView(activeEl);
        }
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(key: KeyboardEvent) {
    this.hasShiftKey = key.shiftKey;
  }
}
