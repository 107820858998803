import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '../components/loader';
import { I18nModule } from '../i18n';
import { PipesModule } from '../pipes/pipes.module';
import { TableComponent } from './table.component';

@NgModule({
  declarations: [TableComponent],
  imports: [
    CommonModule,
    I18nModule,
    LoaderModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    MatButtonModule,
    MatCheckboxModule,
    ScrollingModule,
    PipesModule,
    TranslateModule,
    RouterModule,
  ],
  exports: [TableComponent, MatTableModule],
})
export class TableModule {}
