import { InjectionToken } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

export interface TableConfig {
  id: string;
  columns: TableColumn[];
  // rows?: TableRow;
  preCalculatedRowClass?: (row: TableRow) => { [key: string]: boolean };
  hidden: string[];
  onHighlight?: (row: Record<string, unknown>) => void;
  onReset?: (row: Record<string, unknown>) => void;
  hideFooter?: boolean;
  sortType?: 'local' | 'remote';
  sortable?: boolean;
}

export type ColumnType = 'numeric' | 'number' | 'percent' | 'date' | 'datetime' | 'button' | 'boolean';

export interface TableColumn {
  id: string;
  key?: string;
  name: string | (() => string);
  showTooltip?: boolean;
  tooltipText?: (row: any) => string;
  addHead?: string | (() => string);
  unit?: string | (() => string);
  formatter?: ColumnFormatter;
  formatAsHtml?: boolean;
  classList?: (row: any) => { [key: string]: string };
  type?: ColumnType;
  color?: string;
  decimals?: number;
  align?: 'left' | 'right' | 'center';
  translatable?: boolean;
  summarize?: boolean;
  average?: boolean;
  filterable?: boolean;
  disabled?: boolean;
  routeTo?: (row: any) => any[];
  queryParams?: (row: any) => { [key: string]: any };
  sortable?: boolean;

  showIf?: (row: any) => boolean;
  className?: string;

  /**
   * Only applicable for columns of type: 'button'. This is the click handler
   * @param row
   * @returns
   */
  action?: (row: TableRow, column: TableColumn) => void;
  label?: string;
  /** The icon to set to a column. If this returns '__spinner__' a load spinner will be displayed */
  icon?: string | ((row: any) => string);

  /**
   * Monitors clicks in the checkbox for this column, and updates the model
   *
   * Only applicable if `type: 'boolean'`
   */
  changeable?: boolean; // Applies to type 'boolean' columns
  onChange?: (row: TableRow) => void;

  /**
   * Places a checkbox in the header, allowing users to toggle checkbox state
   * for all visible rows.
   *
   * Only applicable if `type: 'boolean'` AND `changeable: true`.
   *
   * <b>Default: false</b>
   */
  toggleHeader?: boolean;
}

export type ColumnFormatter = (val: any, row: TableRow) => string | SafeHtml;

export interface TableRow {
  _active?: boolean;
  _selected?: boolean;
  _class?: string;
  // class?: (row: TableRow) => { [key: string]: boolean };
  [key: string]: any;
}

export interface TableFilterEvent {
  row: TableRow;
  column: Partial<TableColumn>;
}

export const DEFAULT_TABLE_ROW_SIZE = new InjectionToken<number>('table.row.size');
