import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport, CdkVirtualScrollRepeater } from '@angular/cdk/scrolling';
import { DataSource } from '@angular/cdk/table';
import { Injectable, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { DataFilter } from './data-filter';

@Injectable()
export class ScrollableDataSource<T> extends DataSource<T> implements CdkVirtualScrollRepeater<T>, OnDestroy {
  private _subscriptions: Subscription[] = [];
  private _viewPort!: CdkVirtualScrollViewport;

  // Create MatTableDataSource so we can have all sort,filter bells and whistles
  private _matTableDataSource: MatTableDataSource<T> = new MatTableDataSource();
  readonly filter = new DataFilter();

  // Expose dataStream to simulate VirtualForOf.dataStream
  dataStream = combineLatest([this._matTableDataSource.connect().asObservable(), this.filter.filterChanged$]).pipe(
    // Apply consumer filter
    map(([data, filter]) => (data = filter ? data.filter(filter) : data))
  );

  // Pass through the complete dataset to the underlying MatTableDataSource
  get data() {
    return this._matTableDataSource.data;
  }
  set data(data: T[]) {
    this._matTableDataSource.data = data;
  }

  set sort(sort: MatSort) {
    this._matTableDataSource.sort = sort;
  }

  // What is actually rendered
  renderedStream = new BehaviorSubject<T[]>([]);

  // Does not appear to ever be called, but is required by the CdkVirtualScrollRepeater interface
  measureRangeSize(range: ListRange, orientation: 'horizontal' | 'vertical'): number {
    return -1;
  }

  // Must be called with a reference to the <cdk-virtual-scroll-viewport> element.
  attach(viewPort: CdkVirtualScrollViewport) {
    if (!viewPort) {
      throw new Error('ViewPort is undefined');
    }
    this._viewPort = viewPort;

    // Attach DataSource as CdkVirtualForOf so ViewPort can access dataStream
    this._viewPort.attach(this);

    // Trigger range change so that 1st page can be loaded
    this._viewPort.setRenderedRange({ start: 0, end: 50 });
  }

  // Called by CDK Table
  connect(): Observable<T[]> {
    const filtered = this._viewPort === undefined ? this.dataStream : this.filterByRangeStream(this.dataStream);
    this._subscriptions.push(filtered.subscribe(data => this.renderedStream.next(data)));
    return this.renderedStream.asObservable();
  }

  disconnect(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  // Does the actual splice of the dataset.
  private filterByRangeStream(tableData: Observable<T[]>): Observable<T[]> {
    const rangeStream = this._viewPort.renderedRangeStream.pipe(startWith({} as ListRange));
    const filtered = combineLatest([tableData, rangeStream]).pipe(
      // Apply page slice
      map(([data, { start, end }]) => (start == null || end == null ? data : data.slice(start, end)))
    );
    return filtered;
  }

  ngOnDestroy() {
    this.disconnect();
  }
}
