import { BehaviorSubject } from 'rxjs';

export type FilterFn = (value: any, index?: number, array?: any[]) => boolean;

export class DataFilter {
  filterChanged$ = new BehaviorSubject<FilterFn | null>(null);

  private _filter!: FilterFn;
  set filter(filter: FilterFn) {
    this._filter = filter;
    this.filterChanged$.next(filter);
  }
}
